@charset "UTF-8";
@import "_fonts.scss";
@import "_variables.scss";


.flex-space-between {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.flex-column-around {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.flex-justify-between {
   display: flex;
   justify-content: space-between;
 }
.flex-justify-center {
  display: flex;
  justify-content: center;
}
.flex-justify-left {
    display: flex;
    justify-content: left;
}
.flex-justify-around {
    display: flex;
    justify-content: space-around;
}

.container_10 {
    padding: 0 10px;
}
.row_10 {
    margin: 0 -10px;
    &:after {
        content: " ";
        display: table;
        clear: both;
    }
    .col_1, 
    .col_2, 
    .col_3, 
    .col_4,
    .col_5,
    .col_6,
    .col_7,
    .col_8,
    .col_9,
    .col_10 {
        position: relative;
        min-height: 1px;
        padding-left: 10px;
        padding-right: 10px;
        float: left;
    }
    .col_1 {
        width: 10%;
    }
    .col_2 {
        width: 20%;
    }
    .col_3 {
        width: 30%;
    }
    .col_4 {
        width: 40%;
    }
    .col_5 {
        width: 50%;
    }
    .col_6 {
        width: 60%;
    }
    .col_7 {
        width: 70%;
    }
    .col_8 {
        width: 80%;
    }
    .col_9 {
        width: 90%;
    }
    .col_10 {
        width: 100%;
    }
    .col_offset_1 {
        margin-left: 10%;
    }
    .col_offset_2 {
        margin-left: 20%;
    }
    .col_offset_3 {
        margin-left: 30%;
    }
    .col_offset_4 {
        margin-left: 40%;
    }
    .col_offset_5 {
        margin-left: 50%;
    }
    .col_offset_6 {
        margin-left: 60%;
    }
    .col_offset_7 {
        margin-left: 70%;
    }
    .col_offset_8 {
        margin-left: 80%;
    }
    .col_offset_9 {
        margin-left: 90%;
    }
    .col_offset_10 {
        margin-left: 100%;
    }
}

body, html {
    font-family: "BookAntiqua";
    background-color: #ffffff;
    width: 100vw;
    overflow-x: hidden !important;
}
//мой css
$secs: .2s;
$color: #ffffff;
.bg-faded {
  background-color: $color;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1440px;
  }
}
@keyframes spin-text {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(-360deg);
  }
}
* {
  outline: none;
}
a:hover,
a:focus,
a:active {
  text-decoration: none !important;
  color: #8c7457 !important;
  outline: none !important;
}
.nav-logo {
  display: none;
}
.navbar-toggler {
  position: relative;
  z-index: 1;
}
.initials_img {
  display: none;
}
.caption.-gradient {
  font-size: 40px;
  font-family: BookAntiqua;
  font-weight: 700;
  color: #7f674a;
  background: linear-gradient(45deg,#7f674a,#cfb697);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
p.-paragraph {
  color: #9d9893;
  font-size: 20px;
  font-family: "PFDinDisplayPro";
}
.info__read {
  display: block;
}
.slider {
  position: relative;
}
.text {
  .clock {
    font-size: 14px;
    font-family: "PFDinDisplayPro";
    color: rgba(255, 255, 255, 0.502);
    position: relative;
    &:before {
      content: url('/_img/clock.png');
      display: inline-block;
      position: relative;
      top: 5px;
      padding-right: 15px;
    }
  }
}
header {
  height: 4.6875rem;
  .container.-header {
    padding: 0;
    max-width: 100vw;
    position: fixed;
    z-index: 1000;
    width: 100%;
    box-shadow: 0px 0px 15px #d6c7b8;
  }
}
.navbar-nav .nav-item+.nav-item {
    margin-left: 2rem;
}
.navbar-brand {
  padding-top: .7rem;
  margin-right: 3rem;
  width: 100px;
  font-size: 24px;
  font-weight: 600;
  line-height: 0.6;
  text-align: center;
  &:hover {
    text-decoration: none !important;
  }
  &__middle {
    font-size: 14px;
    color: #856d50;
  }
  &__bottom {
    font-size: 10px;
    letter-spacing: 3px;
  }
}
.nav-link {
  font-size: 13.521px;
  font-family: "PFDinDisplayPro";
  color: rgb(187, 159, 125) !important;
  text-transform: uppercase;
  padding: 1.2rem 0 !important;
}
.tel {
  font-size: 13.521px;
  font-family: "BookAntiqua";
  color: rgb(140, 116, 87);
  font-weight: 700;
  line-height: 1.2;
  margin-right: 2rem;
  position: relative;
  &:hover {
    text-decoration: none !important;
  }
  &:before {
    content: url("/_img/tel.png");
    position: relative;
    top: .4rem;
    display: inline-block;
    margin: 0 0.6rem 0 1.5rem;
    transition: $secs;
  }
  &:hover:before {
    transform: rotate(30deg);
  }
}
.btn-outline-success {
 &.-tel {
    font-family: "PFDinDisplayPro";
    text-transform: uppercase;
    background: #6f583c;
    background: linear-gradient(45deg, #6f583c 33%, #826a4d 66%, #bea588);
    color: #ffffff;
    border: none;
    box-shadow: 7px 6px 9px #e5e2db;
    transition: $secs;
    border-radius: 0;
    color: $color !important;
    &:focus {
      outline: none;
    }
    &:hover {
      box-shadow: 0 0 25px #6f583c;
      outline: none;
    }
    padding: 1.1rem 2.9rem;
    font-size: 1rem;
  }
}
.inner_slide {
  padding-top: 3.3rem;
  h1 {
      font-family: "BookAntiqua";
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 1.05;
      background: linear-gradient(45deg, #7f674a, #cfb697);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 1.3rem;
  }
  .paragraph {
    font-size: 18.656px;
    font-family: "PFDinDisplayPro";
    color: rgb(121, 115, 108);
    line-height: 1.85;
    margin-bottom: 3.7rem;
  }
  &.-two {
    background: #fff;
    .paragraph-img {
      float: left;
      padding-right: 2.2rem;
    }
    .box {
      padding: 0 4rem 2.2rem;
      position: relative;
      transition: $secs;
      background: $color;
      &:after {
        position: absolute;
        content: '';
        top: 107%;
        left: 15%;
        height: 3px;
        border-radius: 50%;
        width: 47rem;
        background-color: rgba(127,103,74,.09);
        box-shadow: 0px 0px 25px rgba(127,103,74,.7);
        transition: 0.2s;
      }
      &:hover {
        transform: translateY(15px);
      }
      &:hover:after {
        transform: translateY(-15px);
        background-color: rgba(127,103,74,.16);
        width: 35rem;
        box-shadow: 0px 0px 15px rgba(127,103,74,0.7);
        left: 25%;
      }
    }
    .caption {
      font-size: 24px;
      font-family: "BookAntiqua";
      color: rgb(121, 115, 108);
      font-weight: 700;
      line-height: 2.083;
    }
  }
}
.services {
  width: 97%;
  margin: 0 auto 6rem;
  p {
    font-size: 18.656px;
    font-family: "BookAntiqua";
    color: rgb(121, 115, 108);
    font-weight: bold;
    line-height: 1.4;
    margin-top: 20px;
  }
  &__link {
    font-size: 13px;
    font-family: "PFDinDisplayPro";
    color: rgb(187, 159, 125);
    text-transform: uppercase;
    line-height: 2;
    position: relative;
    transition: $secs;
    &:hover {
      text-decoration: none;
      color: #eac597;
    }
    &:hover:after {
      transform: translateX(-8px);
    }
    &:active:after {
      transform: translateX(8px);
    }
    &:after {
      position: absolute;
      content: url("/_img/arrow-link.png");
      right: 0;
      top: 0;
      right: -33%;
      top: -15%;
      transition: $secs;
    }
  }
}
.advice {
  img {
    display: block;
    padding-bottom: 10px;
  }
}
.years {
  font-size: 32px;
  font-family: "BookAntiqua";
  font-weight: 700;
  line-height: 1.302;
  background: linear-gradient(45deg, #7f674a, #cfb697);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  &__numeral {
      font-size: 150px;
  }
}
.sample {
  padding-left: 0;
  list-style-type:none;
  li {
    position: relative;
    font-size: 20px;
    font-family: "PFDinDisplayPro";
    color: rgb(121, 115, 108);
    line-height: 1.6;
    margin-bottom: 0.4rem;
    &:last-child {
      margin-bottom: 1.7rem;
    }
    &:before {
      position: relative;
      display: inline-block;
      content: url("/_img/cube_in_cube.png");
      padding-right: 1.25rem;
    }
  }
}
.circle {
    font-size: 80px;
    font-family: "BookAntiqua";
    color: rgb(121, 115, 108);
    font-weight: bold;
    line-height: 0.4;
    height: 6.5625rem;
    width: 6.5625rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    text-align: center;
    .circus-min {
      padding-right: 0.25rem;
      height: 105px;
      width: 105px;
      height: 35%;
      display: contents;
      &:after {
        content: '';
        height: 100%;
        width: 100%;
        background: url("/_img/circus-min.png") no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        background-size: contain;
        animation-name: spin;
        animation-duration: 20s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }
    &:before {
      content: '';
      height: 12.4375rem;
      width: 12.4375rem;
      background: url("/_img/circus-big.png") no-repeat;
      position: absolute;
      top: -3rem;
      left: -2.9rem;
      background-size: contain;
      animation-name: spin;
      animation-duration: 20s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
}
.circle-text {
  font-family: monospace;
  font-size: 16px;
  position: absolute;
  font-weight: 300;
  top: -25%;
  left: 44%;
  animation-name: spin-text;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: 5px 80px;
  span {
    height: 80px;
    display: inline-block;
    position: absolute;
    width: 10px;
    transform-origin: bottom center;
    left: 0;
    top: 0;
  }
}
.char1 { transform: rotate(6deg); }
.char2 { transform: rotate(12deg); }
.char3 { transform: rotate(18deg); }
.char4 { transform: rotate(24deg); }
.char5 { transform: rotate(30deg); }
.char6 { transform: rotate(36deg); }
.char7 { transform: rotate(42deg); }
.char8 { transform: rotate(48deg); }
.char9 { transform: rotate(54deg); }
.char10 { transform: rotate(60deg); }
.char11 { transform: rotate(66deg); }
.char12 { transform: rotate(72deg); }
.char13 {transform: rotate(78deg);}
.char14 {transform: rotate(84deg);}
.char15 {transform: rotate(90deg);}
.char16 {transform: rotate(96deg);}
.char17 {transform: rotate(102deg);}
.char18 {transform: rotate(108deg);}
.char19 {transform: rotate(114deg);}
.char20 {transform: rotate(120deg);}
.char21 {transform: rotate(126deg);}
.char22 {transform: rotate(132deg);}
.char23 {transform: rotate(138deg);}
.char24 {transform: rotate(144deg);}
.char25 {transform: rotate(150deg);}
.char26 {transform: rotate(156deg);}
.char27 {transform: rotate(162deg);}
.char28 {transform: rotate(168deg);}
.char29 {transform: rotate(174deg);}
.char30 {transform: rotate(180deg);}
.char31 {transform: rotate(186deg);}
.char32 {transform: rotate(192deg);}
.char33 {transform: rotate(198deg);}
.char34 {transform: rotate(204deg);}
.char35 {transform: rotate(210deg);}
.char36 {transform: rotate(216deg);}
.char37 {transform: rotate(222deg);}
.char38 {transform: rotate(228deg);}
.char39 {transform: rotate(234deg);}
.char40 {transform: rotate(240deg);}
.char41 {transform: rotate(246deg);}
.char42 {transform: rotate(254deg);}
.char43 {transform: rotate(260deg);}
.char44 {transform: rotate(266deg);}
.char45 {transform: rotate(272deg);}
.char46 {transform: rotate(278deg);}
.char47 {transform: rotate(284deg);}
.char48 {transform: rotate(290deg);}
.char49 {transform: rotate(296deg);}
.char50 {transform: rotate(302deg);}
.char51 {transform: rotate(308deg);}
.char52 {transform: rotate(314deg);}
.char53 {transform: rotate(320deg);}
.char54 {transform: rotate(326deg);}
.char55 {transform: rotate(332deg);}
.char56 {transform: rotate(338deg);}
.char57 {transform: rotate(344deg);}
.char58 {transform: rotate(350deg);}
.char59 {transform: rotate(356deg);}
.char60 {transform: rotate(362deg);}
.char61 {transform: rotate(368deg);}
.char62 {transform: rotate(374deg);}
.char63 {transform: rotate(380deg);}
.char64 {transform: rotate(386deg);}
.char65 {transform: rotate(392deg);}
.char66 {transform: rotate(398deg);}
.char67 {transform: rotate(404deg);}
.char68 {transform: rotate(410deg);}
.char69 {transform: rotate(416deg);}
.char70 {transform: rotate(424deg);}
.char71 {transform: rotate(430deg);}
.char72 {transform: rotate(436deg);}
.char73 {transform: rotate(442deg);}
.char74 {transform: rotate(448deg);}
.char75 {transform: rotate(454deg);}
.char76 {transform: rotate(460deg);}
.imagecontainer{
   float: left;
   margin: 17.5px auto;
   display:block;
   width: 70px;
   height: 70px;
   overflow:hidden;
   transform: rotate(45deg);
   position:relative;
   background-color: #b3b6b6;
   cursor: pointer;
  .block_img{
    transform: rotate(-45deg);
    width: 150%;
    height: 150%;
    position: absolute;
    left: -25%;
    top: -25%;
  }
  .item_img {
    padding-bottom: 100%;
    background-size: cover;

  }

 }
.imagecontainer_slick{
  float: left;
  display:block;
  width: 150px;
  height: 150px;
  overflow:hidden;
  transform: rotate(45deg);
  position:relative;
  background-color: #b3b6b6;
  cursor: pointer;
  filter: drop-shadow( 16px -16px 0px #d6c1a8);
  transition: $secs;
  &:hover{
    filter: drop-shadow(0px 0 #d6c1a8) opacity(0.7);
  }
  .image_block {
    transform: rotate(-45deg);
    width: 164%;
    height: 164%;
    position: absolute;
    left: -49px;
    top: -44px;
    overflow: hidden;
.item_img{
  background-size: cover;
  padding-bottom: 100%;
  background-repeat: no-repeat;
}


  }
}
.imagecontainer2 {
  float: left;
  margin: 17.5px auto;
  display:block;
  width: 240px;
  height: 240px;
  left: 10%;
  top: 6.8rem;
  z-index: 0;
  overflow:hidden;
  transform: rotate(45deg);
  position:absolute;
  background-color: #b3b6b6;
  .block_img{
    transform: rotate(-45deg);
    width: 350px;
    height: 350px;
    position: absolute;
    left: -57px;
    top: -55px;
    overflow: hidden;
  }
  .item_img {
    padding-bottom: 100%;
    background-size: cover;

  }

}
.service {
  max-width: 455px;
  &__caption {
    font-size: 30px;
    color: #79736c;
    font-weight: 700;
  }
  p {
    font-size: 16px;
    font-family: "PFDinDisplayPro";
    color: #79736c;
    line-height: 1.375;
    margin: 16px 0 22px 0;
  }
}
.time {
  font-size: 20px;
  color: rgba(121, 115, 108, 0.8);
  line-height: 2;
  text-transform: lowercase;
  display: flex;
  &:before {
    content: url(/_img/clock.png);
    display: inline-block;
    position: relative;
    top: 5px;
    padding-right: 15px;
    position: relative;
  }
  &.-two {
    line-height: 1.4;
    &:before {
      content: url(/_img/exclamation.png);
    }
  }
}
.price {
  font-size: 50px;
  color: #79736c;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 30px;
  span {
    font-size: 20px;
  }
  &.-two {
    font-size: 36px;
    margin: 30px 0;
    .price__span {
      font-size: 16px;
    }
  }

}
.submit {
  height: 56px;
  border-radius: 3px;
  font-family: 'PFDinDisplayPro';
  text-transform: uppercase;
  background: linear-gradient(45deg,#6f583c 33%,#826a4d 66%,#bea588);
  border: none;
  transition: 0.2s;
  color: #fff;
  font-size: 1rem;
}
.form {
form {
    max-width: 245px;
    span {
      font-size: 14px;
      font-family: PFDinDisplayPro;
      color: #79736c;
    }
  }
.area {
    border: none;
    font-size: 1.5rem;
    background-color: $color;
    border-bottom: 2px solid #a88f72;
    width: 100%;
  }
}
.submit {
  height: 56px;
  width: 240px;
  border-radius: 3px;
  font-family: 'PFDinDisplayPro';
  text-transform: uppercase;
  background: linear-gradient(45deg, #6f583c 33%, #826a4d 66%, #bea588);
  border: none;
  box-shadow: 7px 6px 9px #e5e2db;
  transition: $secs;
  color: #fff;
  font-size: 1rem;
  &:hover {
    box-shadow: 0 0 25px #6f583c !important;
  }
}
.lavnik {
    margin-bottom: 111px;
    &__one {
      background: url("/_img/one.png") no-repeat;
      background-size: cover;
      background-position-y: 15%;
      position: relative;
      .container {
        position: relative;
        z-index: 1;
      }
      .background {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          background: url(/_img/podium.png) no-repeat;
          background-size: cover;
          background-position-x: center;
          width: 100vw;
          height: 50%;
          border-radius: 20%;
          filter: blur(5px);
          background-color: $color;
          top: 60%;
          z-index: 0;
        }
      }
      .wrapper_right {
        width: 74%;
      }
      .consultation {
        font-size: 15.678px;
        font-family: "PFDinDisplayPro";
        color: rgb(150, 139, 142);
        line-height: 1.571;
      }
      .wrap {
        width: 28.73%;

      }
      .service {
        background: $color;
        padding: 30px 0;
        box-shadow: 0px 10px 40px 0px rgba(129, 104, 74, 0.12);
        position: relative;
        transition: $secs;
        &:after {
          position: absolute;
          content: '';
          top: 118%;
          left: 15%;
          height: 3px;
          border-radius: 50%;
          width: 200px;
          background-color: rgba(127,103,74,.09);
          box-shadow: 0px 0px 25px rgba(127,103,74,.7);
          transition: $secs;
        }
        &:hover {
          transform: translateY(15px);
        }
        &:hover:after {
          transform: translateY(-15px);
          background-color: rgba(127,103,74,.16);
          width: 150px;
          box-shadow: 0px 0px 15px rgba(127,103,74,0.7);
          left: 25%;
        }
        img {
          width: 43%;
        }
        &.-two {
          img {
            width: 44%;
          }
        }
        &.-three {
          img {
            width: 47%;
          }
        }
      }
      .inner {
        width: 76%;
        margin: 0 auto;
      }
      .slick-dots {
        position: relative;
        left: 0;
        bottom: 5px;
      }
      .slick-dots li button:before {
        color: #ba9c79;

      }
      .slick-dots li.slick-active button:before {
        background: linear-gradient(45deg, #7f674a, #cfb697);
      }
      .block {
        &.-two {
          width: 23%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
    &__two {
      background: url('/_img/two.png') no-repeat 100% 35%;
      background-size: 80%;
      position: relative;
      #advantages {
        position: absolute;
        top: -135px;
        height: 100px;
      }
      &__background {
        background: url(/_img/people.png) no-repeat 90% 100%;
        background-size: 35%;
        margin-bottom: -25px;
      }
      .caption {
        width: 48rem;
        margin: 0 auto;
        color: #7f674a;
        position: relative;
        z-index: 1;
        /*@media screen and (-webkit-min-device-pixel-ratio:0) {
          background: -webkit-linear-gradient(45deg,#7f674a,#cfb697);
          background: -o-linear-gradient(45deg,#7f674a,#cfb697);
          background: linear-gradient(45deg,#7f674a,#cfb697);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }*/
         h2 {
          font-size: 2.332rem;
          font-family: "BookAntiqua";
          font-weight: 700;
          line-height: 2.05;
        }
        p {
          font-size: 1.166rem;
          font-family: "PFDinDisplayPro";
          color: rgb(121, 115, 108);
          line-height: 1.6
        }
      }
      &__wrap {
        position: relative;
      }
      .advantages {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-content: flex-start;
        margin: 4.2rem 30rem 5.3rem 0;
      }
      .number {
        font-size: 1.5rem;
        font-family: "BookAntiqua";
        color: #c9b092;
        line-height: 1.231;
        margin-bottom: 2.25rem;
        position: relative;
        margin-left: 22px;
        &:after {
          position: absolute;
          content: '';
          width: 2.5rem;
          height: 2.5rem;
          border: 2px solid #fcc45e;
          top: -0.4rem;
          left: -0.9rem;
          transform: rotate(45deg);
        }
      }
      .item {
        width: 15rem;
        padding: 0 0 2rem 0;
        span {
          font-family: "BookAntiqua";
          color: rgb(255, 255, 255);
          line-height: 1.231;
        }
        &__caption {
          font-size: 22px;
          font-family: "BookAntiqua";
          color: #79736c;
          line-height: 1.231;
          font-weight: 700;
        }
      }
      .paragraph {
        font-size: 15px;
        font-family: "PFDinDisplayPro";
        color: rgb(121, 115, 108);
        line-height: 1.375;
      }

    }
    &__three {
      background: linear-gradient(45deg,#ab875c 33%,#b5956d 66%,#ceb596);
      margin-right: 4.5rem;
      margin-bottom: 100px;
      box-shadow: -30px 16px #967855;
      .slide {
        position: relative;
        &:before {
          position: absolute;
          content: '';
          width: 540px;
          height: 540px;
          background: url(/_img/imagecontainer.png) no-repeat;
          background-size: contain;
          left: 5%;
          top: 7rem;
        }
      }
      .info {
        font-family: "PFDinDisplayPro";
        font-size: 20px;
        width: 710px;
        margin: 58px 0 0 31%;
        position: relative;
      }
      .caption {
        font-family: "BookAntiqua";
        font-size: 37.312px;
        font-weight: 700;
        line-height: 2.05;
      }
      .initials {
        font-family: "BookAntiqua";
        font-style: italic;
        font-size: 28px;
        line-height: 2.733;
      }
      .position {
        font-size: 12px;
        text-transform: uppercase;
        line-height: 1.539;
        padding-bottom: 23px;
      }
      .list {
        color: rgba(255, 255, 255, 0.502);
        line-height: 1.5;
        padding-right: 20px;
      }
      .text-gradient {
        background: linear-gradient(45deg, #ffffff, #f3e1cb);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        &.-p {
          padding-top: 29px;
        }
      }
    }
    &__four {
      font-family: "BookAntiqua";
      margin-bottom: 4.5rem;
      .caption {
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 1.05;
        background: linear-gradient(45deg,#7f674a,#cfb697);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 3rem;
        text-align: center;
      }
      ul {
        list-style-type: none;
      }
      .btn {
        outline: 1px solid #c1a481;
        width: 235px;
        padding: 1rem 0;
        color: #c1a481;
        font-size: 16px;
        text-transform: uppercase;
        border: none;
        margin-bottom: 15px;
        &:hover {
          box-shadow: 0 0 20px #7a6646;
          background: linear-gradient(45deg,#6f583c 33%,#826a4d 66%,#bea588);
          color: #fff !important;
        }
      }
      .more_info {
        border-bottom: 1px dashed #c1a481;
        font-size: 16px;
        font-family: "PFDinDisplayPro";
        color: rgb(163, 141, 114);
        line-height: 1.876;
        padding-bottom: 5px;
        &:hover {
          text-decoration: none;
        }
      }
      li {
        font-size: 20px;
        font-family: "PFDinDisplayPro";
        color: #79736c;
        line-height: 2;
        position: relative;
        &:before {
          position: absolute;
          content: url(/_img/cube_in_cube.png);
          left: -2.5rem;
          top: 5px;
        }
      }
      .inner {
        padding: 40px;
        transition: $secs;
        outline: 1px solid #e9e0d5;
        &.-primary {
          background: #678771;
          background: url("/_img/cube_fon.png") no-repeat,
          linear-gradient(45deg,#678771 33%,#72947c 66%,#83aa8f);
          background-repeat: no-repeat;
          background-position: 100% 100%;
          outline: none;
          .time {
            color: rgba(255, 255, 255, 0.502);
          }
          .service__caption {
            color: $color;
          }
          p {
            color: $color;
            span {
              background-color: #596f60;
            }
          }
          li {
            color: $color;
            &:before {
              content: url(/_img/cube_in_cube2.png);
            }
          }
          .price {
            color: $color;
          }
          .btn {
            background: linear-gradient(45deg,#6f583c 33%,#826a4d 66%,#bea588);
            outline: none;
            color: $color;
          }
          .more_info {
            color: $color;
            border-bottom: 1px dashed $color;
          }
        }
      }
    }
    &__five {
      .caption {
        font-size: 40px;
        font-family: "BookAntiqua";
        font-weight: 700;
        background: linear-gradient(45deg,#7f674a,#cfb697);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        margin-bottom: 30px;
      }
      .issues {
        display: flex;
        justify-content: center;
        .list {
          max-width: 750px;
          width: 90vw;
          list-style-type: none;
          &__inner {
            color: #a38d72;
            font-size: 20px;
            font-family: "PFDinDisplayPro";
            line-height: 2;
            display: flex;
            justify-content: space-between;
            transition: $secs;
            &:hover {
              color: #514b45;
              cursor: pointer;
              .number {
                color: #514b45;
              }
            }
          }
        }
        .answer {
          display: none;
        }
        li {
          transition: $secs;
          border-bottom: 1px dashed #c1a88b;
          margin-bottom: 2.9rem;
          .expand {
            img {
              transition: $secs;
            }
          }
          &.border {
            border-bottom: none;
            position: relative;
            .list__inner {
              color: #514b45;
              &:after {
                max-width: 770px;
                width: 90vw;
                content: '';
                border: 1px solid #c1a88b;
                position: absolute;
                left: -20px;
                height: 110%;
                top: -5%;
                transition: $secs;
              }
            }
            .expand {
              img {
                transform: rotate(180deg);
              }
            }
          }
          .number {
            color: #000000;
            padding-right: 0.625rem;
            transition: $secs;
          }
          .expand {
            flex-grow: 1;
            text-align: right;
          }
        }
      }
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 18vw;
        height: 100vh;
        background: url(/_img/calc.png) no-repeat;
        right: 0;
        top: -65%;
        background-position-x: right;
        background-size: contain;
        transition: all .4s linear;
        z-index: 1;
      }
      &.calc {
        &:before {
          right: -15%;
        }
      }
      .clip {
        position: absolute;
        top: 60%;
        img {
          &:first-child {
            animation: rotateClip 35s linear infinite;
          }
           &:last-child {
            position: relative;
            left: -23%;
            top: -48px;
             animation: rotateClip 60s linear infinite;
          }
        }
      }
    }
    &__six {
      background: #203225 url("/_img/background-six.png") no-repeat;
      background-size: cover;
      font-family: "BookAntiqua";
      position: relative;
      margin: 9.125vw 0;
      &:after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border: 0 solid transparent;
        border-left-width: 0px !important;
        border-right-width: 100vw !important;
        border-top: 9.125vw solid #203225 !important;
        top: 100%;
        left: 0;
      }
      &:before {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border: 0 solid transparent;
          border-right-width: 0px;
          border-left-width: 100vw;
          border-bottom: 9.125vw solid #203225;
          top: -9.125vw;
        }
      .caption {
        font-size: 30px;
        font-weight: 700;
        color: $color;
        &.-one {
          padding-top: 3.125rem;
          background: linear-gradient(45deg, $color, #f3e1cb);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 2.6rem;
          text-align: center;
          max-width: 650px;
          margin: 0 auto;
          padding-bottom: 30px;
        }
        &.-two {
          margin-bottom: 3.1rem;
          flex-basis: 100%;
        }
        &.price {
          text-align: center;
          font-size: 40px;
          span {
            font-size: 30px;
          }
        }
      }
      .paragraph {
        background: linear-gradient(45deg, $color, #f3e1cb);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1rem;
        text-align: center;
        max-width: 950px;
        margin: 0 auto;
      }
      .block {
        &.-one {
          margin-top: 3.1rem;
          margin-bottom: 2.5rem;
          color: $color;
        }
        &.-two {
          .btn {
            margin: 1.6rem auto 0;
            width: 240px;
            display: block;
            box-shadow: none;
            padding: 1.1rem 0;
            transition: $secs;
            &:hover {
              box-shadow: 0 0 25px #6f583c;
            }
          }
        }
      }
      .sign {
        text-align: center;
        display: block;
        color: $color;
        position: relative;
        &:before {
          content: url("/_img/sign.png");
          position: relative;
          top: 5px;
          padding-right: 15px;
        }
      }
      ul {
        padding-left: 3rem;
        max-width: 16.25rem;
      }
      li {
        list-style-type: none;
        position: relative;
        font-size: 20px;
        font-family: "PFDinDisplayPro";
        padding: 0.5rem 0;
        &:before {
          position: absolute;
          content: url(/_img/cube_in_cube2.png);
          left: -3rem;
          top: 0.8rem;
        }
      }
      .scheme {
        flex-wrap: wrap;
        width: 100%;
        max-width: 990px;
        margin: 0 2% 0 5%;
      }
      .item {
        padding-bottom: 2.6rem;
      }
      .description {
        max-width: 470px;
        width: 100%;
        background-color: #415841;
        padding: 2rem 40px 0;
        position: relative;
        margin-top: 3.6rem;
        max-height: 520px;
        p {
          font-size: 20px;
          font-family: "PFDinDisplayPro";
        }
        &:before {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border: 0 solid transparent;
          border-left-width: 0px !important;
          border-right-width: 470px !important;
          border-top: 52px solid #415841 !important;
          top: 100%;
          left: 0;
        }
        &:after {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border: 0 solid transparent;
          border-right-width: 0px;
          border-left-width: 470px;
          border-bottom: 52px solid #415841;
          top: -52px;
          left: 0;
        }
      }
      .text {
        max-width: 315px;
        padding-left: 1.25rem;
        width: 80%;
        .caption {
          font-size: 20px;
        }
        p {
          font-size: 16px;
          font-family: "PFDinDisplayPro";
        }
      }
      .number {
        background: url('/_img/icon2.png') no-repeat;
        width: 165px;
        background-position: 100% 5%;
        line-height: 0.5;
        font-size: 180px;
        color: rgba(255, 255, 255, 0.2);
        font-weight: 700;
      }
    }
    &__seven {
      margin-bottom: 100px;
      form,
      .caption{
        text-align: center;
      }
      .form form {
        max-width: 100%;
      }
      .caption {
        padding-top: 3.125rem;
        background: linear-gradient(45deg, #6f583c, #ad9578);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 2.6rem;
        padding-bottom: 30px;
      }
      .inner {
        margin-left: 50px;
        display: inline-block;
        text-align: left;
        span {
          font-size: 14px;
          font-family: "PFDinDisplayPro";
          color: rgb(121, 115, 108);
        }
      }
      .area {
        border: none;
        border-bottom: 2px solid #a88f72;
        font-size: 1.5rem;
        width: 245px;
        background: $color;
        &.-name {

        }
        &.-tel {


        }
      }
      .submit {
        height: 56px;
        width: 240px;
        border-radius: 3px;
        font-family: 'PFDinDisplayPro';
        text-transform: uppercase;
        background: linear-gradient(45deg,#6f583c 33%,#826a4d 66%,#bea588);
        border: none;
        box-shadow: 7px 6px 9px #e5e2db;
        transition: 0.2s;
        color: #fff;
        font-size: 1rem;
        margin-left: 70px;
      }
    }
    &__eight {
      font-family: "BookAntiqua";
      background-color: #f6f6f6;
      padding: 100px 0;
      .container {
        max-width: 1170px;
      }
      .caption {
        margin-bottom: 1.1rem;
        &.-min {
          display: none;
        }
      }
      .block {
        position: relative;
        .caption {
          font-size: 30px;
          text-align: center;
        }
      }
      .wrap {
        height: 500px;
        width: 370px;
        //-ms-flex-pack: justify;
        //justify-content: space-evenly;
        background: url(/_img/funnel.png) no-repeat;
        background-size: contain;

      }
      .-paragraph {
        margin-bottom: 1.6rem;
      }
      .text {
        font-family: "BookAntiqua";
        color: #9d9893;
        font-size: 20px;
        position: relative;
        padding-left: 30px;
        &:before {
          position: absolute;
          content: '';
          width: 10px;
          height: 100px;
          background-color: #e5ddd4;
          left: 0;
          top: 0;
        }
        span {
          color: rgba(121, 115, 108, 0.502);
          display: block;
          margin-top: 0.6rem;
        }
      }
      .form__caption {
        font-size: 30px;
        color: rgb(103, 133, 112);
        line-height: 1.4;
        margin-top: 2.5rem;
      }
      .inner {
        font-size: 30px;
        color: rgb(121, 115, 108);
        font-weight: bold;
        line-height: 1.067;
        text-align: center;
        margin: 3% 0;
        &:last-child {
          color: $color;
          padding-bottom: 15px;
          span {
             color: $color;
          }
        }
        .pyramid {
          font-size: 14px;
          font-family: "PFDinDisplayPro";
          color: rgb(121, 115, 108);
          line-height: 2.286;
          display: block;
          font-weight: 400;
        }
      }
      .result {
        position: absolute;
        top: 23rem;
        left: -14rem;
        &:after {
          content: url("/_img/arrow-green.png");
          position: absolute;
          top: 3rem;
          left: 10rem;
        }
      }
      .area {
        background-color: #f6f6f6;
      }
    }
    &__nine {
      font-family: "BookAntiqua";
      background-color: #ede7df;
      padding-top: 120px;
      margin-bottom: 96px;
      .caption {
        text-align: center;
      }
      .info {
        position: relative;
        width: 625px;
        min-height: 260px;
        background-color: $color;
        margin: 3.6rem auto;
        padding: 15px 45px 0;
        &:after {
          content: '';
          position: absolute;
          border: 0 solid transparent;
          top:0;
          left: 0;
          width: 0;
          height: 0;
          border-right-width: 0px !important;
          border-left-width: 625px !important;
          border-bottom: 52px solid $color !important;
          z-index: -1;
          top: -52px;
        }
        &:before {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border: 0 solid transparent;
          left: 0;
          border-left-width: 0px;
          border-right-width: 625px;
          border-top: 52px solid $color;
          top: 100%;
        }
        p {
          font-size: 18px;
          font-weight: 700;
          line-height: 1.25;
          background: linear-gradient(45deg,#7f674a,#cfb697);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .services__link:after {
        right: -55%;
      }
      .percent {
        font-size: 111px;
        line-height: 0.85;
        background: linear-gradient(45deg,#7f674a,#cfb697);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        float: left;
        padding-right: 20px;
      }
      .name {
        margin-left: 15%;
        margin-top: 10%;
        &__caption {
          font-style: italic;
          font-size: 18.656px;
          line-height: 1;
          color: rgba(24, 21, 21, 0.6);
        }
        span {
          font-size: 10.261px;
          font-family: "PFDinDisplayPro";
          color: rgba(24, 21, 21, 0.6);
          text-transform: uppercase;
        }
        &__inner {
          margin-left: 25%;
          padding-top: 20px;
        }
      }
      .slick-dots {
        left: 0;
      }

    }
    &__ten {
      .caption {
        text-align: center;
        margin-bottom: 1.4rem;
      }
      .paragraph {
        font-size: 20px;
        font-family: "PFDinDisplayPro";
        color: rgb(121, 115, 108);
        line-height: 1.6;
        text-align: center;
        margin-bottom: 3rem;
      }
      .btn {
        outline: 1px solid #c1a481;
        width: 235px;
        padding: 1rem 0;
        color: #c1a481;
        font-size: 16px;
        text-transform: uppercase;
        transition: $secs;
        border: none;
        &:hover {
          box-shadow: 0 0 20px #7a6646;
          background: linear-gradient(45deg,#6f583c 33%,#826a4d 66%,#bea588);
          color: #fff !important;
          border-radius: 0;
        }
      }
      .service{
        padding: 30px 35px;
        margin-bottom: 15px;
        height: 100%;
        width: 368px;
        outline: 1px solid #e9e0d5;
        margin: 0 9px;
        color: #c1a481;
        font-size: 16px;
        border: none;
      }
      .service p {
        text-align: left;
        font-size: 16px;
        margin: 16px 0 8px;
        span {
          background-color: #d2dfd6;
        }
      }

    }
    &__eleven {
      position: relative;
      background: url("/_img/coffee.png") no-repeat rgba(0, 0, 0, 0.6);
      background-size: cover;
      color: $color;
      padding-top: 79px;
      margin-top: 10rem;
      padding-bottom: 11.2rem;
      .caption.-one {
        position: relative;
        z-index: 1;
        font-size: 60px;
        display: inline-block;
        top: -9.5rem;
        left: 12rem;
        transform: rotate(-5deg);
        .min {
          font-size: 34px;
        }
        .bold {
          font-weight: 700;
        }
        &:after {
          content: url("/_img/4.png");
          position: absolute;
          right: -25%;
          top: 20%;
        }
        &:before {
          content: url("/_img/5.png");
          position: absolute;
          left: -28.5%;
          top: -163%;
          transform: rotate(5deg)
        }
      }
      .number {
        position: relative;
        font-size: 26px;
        margin-bottom: 2.2rem;
        text-align: center;
        width: 2.5rem;
        &:after {
          position: absolute;
          content: '';
          width: 2.5rem;
          height: 2.5rem;
          border: 2px solid #fcc45e;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%) rotate(45deg);
        }
      }
      .wrapper {
        margin: 0 20px;
      }
      .submit {
        margin-bottom: 1rem;
        box-shadow: 0 0 0 #6f583c;
      }
      .block {
        &.-left {
          width: 290px;
          &.position_end {
            justify-content: end;
          }
          .caption {
            font-size: 40px;
            line-height: 50px;
            background: linear-gradient(45deg, #fff, #f3e1cb);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 2.7rem;
          }
          p {
            font-size: 16px;
            line-height: 22px;
            color: #ffffff87;
          }
        }
        &.-right {
          margin-left: 10.93rem;
          max-width: 888px;
          .link {
            font-family: 'PFDinDisplayPro';
            color: #fff;
            text-transform: uppercase;
            transition: $secs;
            position: relative;
            display: block;
            width: 100%;
            margin: 30px 0 60px;
            span {
              font-size: 1.7rem;
              font-weight: lighter;
              position: relative;
              top: 2px;
              left: 2px;
            }
            &:hover {
              cursor: pointer;
              filter: drop-shadow(0 0 5px rgba(255,255,255, 0.5));
            }
            &.-two {
              display: none;
            }
          }
          &.hide {
            .link{
              &.-one {
                display: none;
              }
              &.-two {
                display: block;
              }
            }

          }
        }
        &__hidden {
          display: none;
          overflow: visible!important;
        }
      }
      .item {
        width: 280px;
        margin-bottom: 2rem;
        &__caption {
          font-size: 26px;
          margin-bottom: 1rem;
        }
        .paragraph {
          font-family: "PFDinDisplayPro";
          font-size: 16px;
        }
      }
      &:after {
        content: '';
        position: absolute;
        border: 0 solid transparent;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-left-width: 0px !important;
        border-right-width: 100vw !important;
        border-top: 155px solid $color !important;
        z-index: 0;
      }
      &:before {
        content: '';
        position: absolute;
        border: 0 solid transparent;
        top: 100%;
        left: 0;
        width: 0;
        height: 0;
        border-right-width: 0px;
        border-left-width: 100vw;
        border-bottom: 155px solid $color;
        z-index: 0;
        transform: translateY(-155px);
      }
    }
    &__twelve {
      .wrap {
        display: flex;
        position: relative;
        div {
          margin: 0 45px;
        }
        &__two {
          display: flex;
          margin: 0 100px;
        }
      }
      .submit {
        width: 98%;
      }
      .form {
        max-width: 465px;
        background: #e9e0d5;
        padding: 50px 50px 85px;
        position: relative;
        top: -100px;
        &__caption {
          font-size: 30px;
          margin-bottom: 1.6rem;
          line-height: 42px;
        }
      }
      .area {
        background: none;
      }
      form {
        width: 247px;
        justify-content: space-around;
        height: 76%;
      }
      .applicants {
        max-width: 500px;
        font-family: 'PFDinDisplayPro';
        .paragraph {
          color: #79736c;
          line-height: 1.6;
          font-size: 20px;
        }
        span {
          display: block;
          font-size: 16px;
          color: #afaba7;
        }
        .link {
          font-size: 20px;
          color: #948f89;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .contacts {
        font-family: 'PFDinDisplayPro';
        font-size: 16px;
        color: #79736c;
        .caption {
          margin-bottom: 1.6rem;
        }
      }
      .address {
        color: #79736c;
        line-height: 1.6;
        font-size: 20px;
        margin-bottom: 1.3rem;
      }
      .metro {
        position: relative;
        padding-left: 38px;
        margin-bottom: 1.3rem;
        &:before {
          content: url(/_img/metro.png);
          content: url(/_img/metro.png);
          display: inline-block;
          position: absolute;
          top: 5px;
          left: 0px;
        }
      }
      .tel {
        position: relative;
        margin-bottom: 1.3rem;
        &:before {
          margin: 0;
        }
        a {
          font-size: 20px;
          color: #79736c;
          font-family: 'PFDinDisplayPro';
          padding: 1rem;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .mail {
        margin-bottom: 1.3rem;
        position: relative;
        padding-left: 38px;
        a {
          font-size: 16px;
          color: #79736c;
          font-family: 'PFDinDisplayPro';
          &:hover {
            text-decoration: none;
          }
        }
        &:before {
          content: url("/_img/mail.png");
          position: absolute;
          left: 0;
          top: 4px;
        }
      }
      .time {
        font-size: 16px;
        margin-bottom: 1.3rem;
      }
      .submit {
        width: 250px;
      }
      .map {
        position: relative;
        right: -45px;
        top: -3rem;
      }
      .map-wrap {
        width: 1170px;
        height: 1420px;
        position: absolute;
        top: 6rem;
        left: -23rem;
        transform: rotate(-45deg);
      }
      .mapcontainer {
        position: absolute;
        top: -33rem;
        left: 28rem;
        box-shadow: -38px 30px #fcf7f2;
        background: #fcf7f2;
        width: 625px;
        height: 83rem;
        transform: rotate(45deg);
        overflow: hidden;
      }

    }
}
@keyframes rotateClip {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
footer {
  font-family: "PFDinDisplayPro";
  padding-top: 65px;
  padding-bottom: 85px;
  color: $color;
  background: linear-gradient(45deg,#a27d4e,#cfb697);
  .wrapper {
    margin: 0 85px;
    max-width: 745px;
  }
  .nav {
    width: 380px;
    &__link {
      font-size: 16px;
      line-height: 30px;
      margin: 0 15px;
      transition: $secs;
      position: relative;
      padding-top: 10px;
      &:after {
        content: '';
      }
      &:hover {
        color: $color !important;
        text-decoration: none;
        filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.5));
      }
      &:active {
        text-decoration: none;
        color: $color;
      }
      &:hover:after {
        position: absolute;
        width: 100%;
        border-bottom: 1px dashed #c4ac8e;
        left: 0;
        top: 93%;
      }
      &:last-child {
        padding-top: 15px;
      }
    }
  }
  a {
    color: $color;
  }
  .socials {
    margin-top: 15px;
    flex-wrap: wrap;
    justify-content: left;
    .decoration {
      width: 50px;
      height: 50px;
      margin: 13px;
    }
    a {
      position: relative;
      width: 50px;
      height: 50px;
      margin: 13px;
      transition: 0.2s;
      img {
        transition: 0.2s;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        position: absolute;
      }
      &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        border: 1px solid #ffffff20;
        top: 0;
        left: 0;
        transform: rotate(45deg);
      }
      &:hover {
        filter: drop-shadow(0 0 10px #ffffff);
      }
    }
  }
  .inner{
    &.-left {
      a {
        &:hover {
          text-decoration: none;
          color: $color !important;
          filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.5));
        }
        &:active {
          color: $color;
        }
      }
      div {
        padding-bottom: 20px;
        &:nth-child(3),
        &:last-child {
          font-size: 14px;
        }
      }
    }
  }
}

#key {
  position: absolute;
  top: -5%;
}
.slick-dots {
  position: relative;
  padding-bottom: 75px;
  left: -265px;
  li {
    margin: 0 3px;
    width: 6px;
    button {
      width: 8px;
    }
  }
  .slick-active {
    width: 25px;
    margin: 0px 3px;
  }
}
.slick-next2 {
  position: absolute;
  width: 160px;
  height: 160px;
  right: 3%;
  top: 33.5%;
  transition: $secs;
  .imagecontainer{
    img {
      filter: drop-shadow(18px 0 #d6c1a8) opacity(0.3);
      transition: $secs;
      &:hover{
        filter: drop-shadow(0px 0 #d6c1a8) opacity(0.7);
      }
    }
  }

  &:before {
    content: url(/_img/slide2-arrow.svg);
    position: absolute;
    left: 44%;
    top: 35%;
    z-index: 1;
  }
}
.slick-dots li button:before {
  color: $color !important;
  opacity: 1;
  width: 8px;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: $color;
  display: inline-block;
  height: 6px;
  width: 27px;
  content: "";
  border-radius: 20%;
  background: $color;
  top: 31.6%;
}
.slick-prev {
  position: absolute;
  width: 72px;
  height: 72px;
  left: -7%;
  top: 50%;
  transition: 0.2s;
  background: #d0bba1;
  transform: rotate(45deg);
  filter: drop-shadow(14px -14px rgba(186, 162, 132, 0.2)) opacity(1);
  z-index: 1;
  &:hover {
    background: #d0bba1;
    filter: drop-shadow(0px 0px rgba(186, 162, 132, 0.2)) opacity(1);
  }
  &:before {
    content: url(/_img/arrow-left.png);
    position: absolute;
    top: 40%;
    left: 40%;
    transform: rotate(-45deg);
  }

}
.slick-next {
  position: absolute;
  width: 72px;
  height: 72px;
  right: -7%;
  top: 50%;
  transition: 0.2s;
  background: #d0bba1;
  transform: rotate(45deg);
  filter: drop-shadow(-14px 14px rgba(186, 162, 132, 0.2)) opacity(1);
  z-index: 1;
  &:hover {
    background: #d0bba1;
    filter: drop-shadow(0px 0px rgba(186, 162, 132, 0.2)) opacity(1);
  }
  &:before {
    content: url("/_img/arrow-right.png");
    position: absolute;
    top: 30%;
    left: 47%;
    transform: rotate(-45deg);
  }
}
.slick-next3 {
    position: absolute;
    width: 160px;
    height: 160px;
    right: 15%;
    top: 33.5%;
    transition: 0.2s;
  img {
    filter: drop-shadow(18px 0 #d6c1a8) opacity(0.3);
    transition: $secs;
    &:hover{
      filter: drop-shadow(0px 0 #d6c1a8) opacity(0.7);
    }
  }
  &:before {
    content: url(/_img/slide2-arrow.svg);
    position: absolute;
    left: 44%;
    top: 35%;
    z-index: 1;
  }
}
.slick-prev3 {
  position: absolute;
  width: 160px;
  height: 160px;
  left: 15%;
  top: 33.5%;
  transition: 0.2s;
  z-index: 1;
  img {
    filter: drop-shadow(-18px 0 #d6c1a8) opacity(0.3);
    transition: $secs;
    &:hover{
      filter: drop-shadow(0px 0 #d6c1a8) opacity(0.7);
    }
  }
  &:before {
    content: url(/_img/slide2-arrow.svg);
    position: absolute;
    left: 44%;
    top: 35%;
    z-index: 1;
    transform: rotate(180deg);
  }
}

.parallax {
  position: absolute;
  transition: all 1s linear !important;
  img {
    width: 14vw;
  }
  &.-one {
    left: 0;
    top: -12%;
  }
}
.tel-mob {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 200ms;
  visibility: hidden;
  opacity: 0;
  z-index: 1001;
  background: rgba(255,255,255,0.9);
  select.area {
    border: none;
    font-size: 18px;
    background-color: #fff;
    border-bottom: 2px solid #a88f72;
    color: #688671;
    margin-bottom: 20px;
  }
  &.open {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  .form {
    .caption {
      font-size: 16px;
      color: #757575;
      text-align: left;
    }
  }
}
.modal_close {
  display: none;
  position: fixed;
  z-index: 1002;
  line-height: .5;
  color: $color !important;
  transform: rotate(45deg);
  background: #b79f83;
  top: 70px;
  right: 90px;
  transition: all 200ms;
  text-decoration: none;
  width: 90px;
  height: 90px;
  font-size: 70px;
  text-align: center;
  div {
    display: inline-block;
    vertical-align: middle;
    height: 0;
    font-family: 'Roboto';
    font-weight: 400;
    transform: translate(0%,2px);
  }
  &:hover {
    box-shadow: 0 0 5px #6f583c;
    cursor: pointer;
  }
  &.open {
    display: block;
  }
}
.popup {
  text-align: center;
  margin: 20vh auto;
  padding: 20px;
  border-radius: 5px;
  width: 580px;
  line-height: 32px;
  position: relative;
  //transition: all 5s ease-in-out;
  &__form {
    width: 305px;
    margin: 0 auto;
    .area {
      background: transparent;
      width: 100%;
    }
    .submit {
      width: 100%;
    }
  }
}
.body__no-scroll {
  overflow: hidden !important;
}
.blur {
  filter: blur(5px);
  //transition: $secs;
}
.popup h2 {
  margin-top: 0;
  text-align: center;
}
.popup .close:hover {
  color: #06D85F;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
  margin-bottom: 55px;
}
@media screen and (max-width: 700px){
  .box{
    width: 70%;
  }
  .popup{
    width: 70%;
  }
}
.bar-chart {

  $barChartBackground: #fff;
  $barColorPrimary: #a38d72;
  $barChartHeight: 6px;
  $barChartCapWidth: 1rem;
  $animationTime: 1.3s;

  background: $barChartBackground;
  height: $barChartHeight;
  width: 770px;

  .bar-chart--inner {
    background: #333;
    height: 100%;
    display: block;
    margin-bottom: .3rem;
    position: relative;
    //will-change: width;
    width: 0;
    transition: all 0.2s ease;

    &:after {
      position: absolute;
      top: 0; right: 0; content: " ";
      width: $barChartCapWidth; height: 100%;
      background: rgba(0,0,0,.1);
    }
  }

  // with animation
  //&[animated] .bar-chart--inner { transition: width $animationTime;}

  // colors
  &.primary .bar-chart--inner { background: $barColorPrimary; }
}
.bar-wrap {
  background: $color;
}
.bar-chart[data-total='1'] {
  .bar-chart--inner {
    width: 21.5%;
  }
}
.bar-chart[data-total='2'] {
  .bar-chart--inner {
    width: 39%;
  }
}
.bar-chart[data-total='3'] {
  .bar-chart--inner {
    width: 64%;
  }
}
.bar-chart[data-total='4'] {
  .bar-chart--inner {
    width: 85%;
  }
}
.bar-chart[data-total='5'] {
  .bar-chart--inner {
    width: 100%;
  }
}
a:hover {
  text-decoration: none !important;
}

@media (max-width: 1280px) {
  .lavnik {
    margin-top: -20px;
    &__one {
      .wrapper_left {
        display: none;
      }
      .wrapper_right {
        width: 85%;
        margin: 0 auto;
      }
    }
    &__three {
      .info {
        width: 610px;
        margin: 0 auto;
        margin-top: 58px;
      }
    }
    &__nine {
      .slick-next3 {
        right: 2%;
      }
      .slick-prev3 {
        left: 2%;
      }
    }
    &__ten {
      .wrapper {
        flex-wrap: wrap;
        justify-content: space-evenly;
        .item {
          margin: 30px;
        }
      }
    }
    &__twelve {
      .map {
        transform: scale(0.8);
      }
    }
  }
  header {
    .navbar-nav {
      display: none;
    }
    .navbar-brand {
      margin-right: 0;
    }
    .tel:before {
      margin: 0 0.6rem 0 0;
    }
  }
}
@media (max-width: 1200px) {
  body,html {
    width: 100%;
  }
  .imagecontainer2 {
    display: none;
  }
  .lavnik {
    margin-bottom: 50px;
    &__one {
      .container {
        max-width: 100% !important;
      }
      .wrapper_right {
        width: 80%;
      }
    }
    &__two {
      .advantages {
        margin: 2rem 18rem 0rem 0;
      }
    }

    &__four {
      .services-two {
        flex-wrap: wrap;
        justify-content: space-evenly;
      }
      .service {
        padding: 10px;
      }
    }
    &__six {
      margin-bottom: 130px;
      .caption {
        font-size: 28px;
        &.-one {
          padding-top: 1rem;
          font-size: 2rem;
        }
      }
    }
    &__eight {
      .-paragraph {
        br {
          display: none;
        }
      }
    }
    &__eleven {
      .caption.-one {
        top: -9.5rem;
        left: 4rem;
        transform: rotate(-9deg);
        &:before {
          left: -20.5%;
          top: -167%;
          transform: rotate(0deg);
        }
      }
      .wrapper {
        flex-direction: column-reverse;
      }
      .block {
        &.-left {
          margin: 3rem auto 0;
        }
        &.-right {
          margin-left: 0;
        }
      }
    }
    &__twelve {
      .map {
        transform: none;
        left: 0;
        top: 0;
      }
      .contacts {
        width: 50%;
      }
      .wrap__two {
        margin: 0;
      }
      .mapcontainer {
        position: relative;
        width: 50vw;
        height: 30rem;
        left:0;
        top: 0;
        transform: none;
        box-shadow: none;
      }
      .map-wrap {
        width: 100%;
        height: 100%;
        transform: none;
        top: 0;
        left: 0;
      }
      .wrap {
        div {
          margin: 0 15px;
        }
      }
    }

  }
  footer {
    .wrapper {
      margin: 0;
      max-width: 100%;
    }
  }
}
@media (max-height: 745px) {
  .popup {
    margin: 5vh auto;
  }

}
@media (max-width: 991px) {
  .lavnik {
    &__one {
      .services__link:after {
        display: none;
      }
      .inner_slide {
        h1 {
          font-size: 2rem;
          margin-bottom: 0.5rem;
        }
        .paragraph {
          font-size: 16px;
          line-height: 1.4;
          margin-bottom: 2.5rem;
        }
      }
      .service {
        padding: 15px 0;
        img {
          width: 30%;
        }
        p {
          margin: 10px 0;
        }
      }
    }
    &__two {
      &__background {
        background: url(/_img/people.png) no-repeat 41% 100%;
        background-size: 30%;
      }
      .caption {
        width: 100%;
        h2 {
          line-height: 1;
          font-size: 2rem;
        }
        p {
          font-size: 1rem;
        }
      }
      .advantages {
        margin: 2rem 0 0 0;
      }
    }
    &__three {
       margin: 0;
      .caption {
        line-height: 1;
        font-size: 2rem;
      }
      .slide {
        &.-one {
          background-size: 22vw;
        }
      }
      .info {
        margin: 3rem auto 0;
        width: 65%;
        padding-right: 2%;
      }
      .text-gradient {
        line-height: 1;
        padding-bottom: 10px;
        &.-p {
          padding-top: 10px;
          font-size: 18px;
        }
      }
      .slick-prev {
        display: none !important;
      }
      .slick-dots {
        left: 0;
        top: -10px;
      }
    }
    &__five {
      .clip {
        display:none;
      }
    }
    &__seven {
      margin-bottom: 50px;
      .form {
        margin: 0 auto;
        width: 50vw;
      }
      .caption {
        font-size: 2rem;
        padding-top: 2rem;
      }
      .inner {
        margin: 25px auto;
        text-align: left;
      }
      .submit {
        margin-left: 0;
      }
    }
    &__eight {
      .caption {
        font-size: 2rem;
      }
      .-paragraph {
        font-size: 18px;
      }
      .result.-paragraph {
        display: none;
      }
    }
    &__nine {
      font-size: 2rem;
    }
    &__ten {
      .caption {
        font-size: 2rem;
      }
    }
    &__eleven {
      .caption.-one {
        transition: none;
        font-size: 3rem;
        &:after,
        &:before {
          display: none;
        }
      }
      .block {
        &.-left {
          width: 100%;
          .caption {
            margin-bottom: 1rem;
            br {
              display: none;
            }
          }
          p {
            margin-top: 20px;
            br {
              display: none;
            }
          }
        }
      }
      .submit {
        width: 50%;
        margin: 0 auto;
      }
    }
    &__twelve {
      .mapcontainer {
        margin: 0 10px;
      }
    }
  }
  .slick-next {
    width: 42px;
    height: 42px;
    right: -5%;
    filter: drop-shadow(-10px 10px rgba(186, 162, 132, 0.2)) opacity(1);
    &:before {
      top: 22%;
      left: 41%;
    }
  }
  .slick-prev {
    width: 42px;
    height: 42px;
    left: -5%;filter: drop-shadow(10px -10px rgba(186, 162, 132, 0.2)) opacity(1);
    &:before {
      top: 28%;
      left: 33%;
    }
  }
  .slick-dots {
    padding-bottom: 0px;
   }
  .slick-next2 {
    transform: scale(0.8);
  }
  .modal_close {
    font-size: 37px;
    display: inline-block;
    right: 50px;
    line-height: 1;
    top: 40px;
    width: 0;
    height: 0;
    div {
      display: inline;
      height: 0;
      background: #947c5e;
      padding: 0 10px;
    }
  }
}
@media (max-width: 767px) {
  /*.slick-slider2 {
    .slick-list {
      max-height: 477px !important;
      &.open {
        max-height: none;
      }
    }
  }*/
  .lavnik {
    &__one {
      .services {
        width: 85%;
        margin: 0 auto;
        flex-wrap: wrap;

      }
      .wrap {
        width: 100%;
      }
      .service {
        padding: 10px 0;
        margin-bottom: 7px;
        box-shadow: none;
        p {
          font-size: 15px;
        }
        img {
          display: none;
        }
        &:hover {
          transform: none;
          box-shadow: none;
        }
        &:after {
          display: none;
        }
      }

      .block {
        &.-two {
          display: none;
        }
      }
      .inner_slide {
        padding-top: 1.3rem;
        &.-two {
          .box {
            padding: 0 10px;
            width: 100%;
            &:hover {
              transform: none;
            }
          }
          .paragraph-img {
            width: 20%;
            padding-right: 10px;
          }
          .caption {
            font-size: 20px;
            line-height: 1.4;
          }
        }
        h1 {
          font-size: 22px;
        }
        .paragraph {
           font-size: 14px;
           margin-bottom: 10px;
         }

      }
      .sample li {
        font-size: 16px;
        &:before {
          display: none;
        }
      }
    }
    &__two {
      &__background {
        background: none;
      }
      .item {
        width: 100%;
      }
    }
    &__three {
      box-shadow: none;

      .info {
        overflow: hidden;
        margin: 35px auto 0px;
        padding: 0;
        width: 90%;
        .caption {
          font-size: 31px;
          margin-bottom: 20px;
        }
        .initials {
          margin: 12px 0;
        }
        .position {
          margin-bottom: 10px;
        }
        .list {
          padding-right: 0;
          font-size: 16px;
          &__value {
            font-size: 14px;
          }
        }
        .list-wrap {
          line-height: 0;
        }
        .text-gradient {
          position: relative;
          z-index: 1;
          padding-bottom: 0;
          &.-p {
            line-height: 1.3;
            font-size: 16px;
          }
        }
        span {
          position: relative;
          z-index: 1;
        }
        /*&__read {
          display: inline-block;
          color: #fff;
          padding: 5px 19px;
          background: rgba(0,0,0, 0.1);
          border-radius: 20px;
          left: 10px;
          font-family: 'PFDinDisplayPro';
          transition: $secs;
          position: relative;
          margin-bottom: 7px;
          &:hover {
            cursor: pointer;
          }
          &.-on {
            display: inline-block;
            &.close {
              display: none;
            }
          }
          &.-off {
            display: none;
            &.open {
              display: inline-block;
            }
          }
        }*/

      }
      .slide {
        &.-one {
          background-image: none;
        }
        &:before {
          background: none;
        }
      }
      .initials-mob {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    &__four {
      margin-bottom:2.5rem;
      .caption {
        margin-bottom: 10px;
      }
      .service {
        max-width: 375px;
        &__caption {
          font-size: 22px;
        }
        p {
          margin: 11px 0;
        }
      }
      .inner {
        padding: 25px;
        &.-primary {
          font-size: 16px;
        }
      }
      li {
        font-size: 16px;
      }
      .price {
        font-size: 32px;
        line-height: 1;
      }
    }
    &__five {
      .caption {
        margin-bottom: 2rem;
        font-size: 2rem;
      }
      .issues {
        justify-content: left;
        .list__inner{
          font-size: 18px;
          line-height: 1.4;
        }
        .answer {
          font-size: 16px;
        }
      }
    }
    &__six {
      padding-bottom: 15px;
      .caption {
        &.-one {
          padding: 1rem 20px 20px;
        }
        &.price {
          margin-bottom: 15px;
          font-size: 28px;
          span {
            font-size: 24px;
          }
        }
      }
      .paragraph {
        padding: 0 20px;
      }
      .block {
        &.-one {
          flex-direction: column;
        }
      }
      .number {
        line-height: .5;
        font-size: 40px;
        color: hsla(0,0%,100%,.2);
        font-weight: 700;
        background: none;
        width: inherit;
      }
      .text {
        max-width: none;
        padding-left: 15px;
        width: inherit;
      }
      .scheme {
        margin: 0 auto;
        padding: 0 20px;
      }
      .description {
        max-width: 320px;
        margin: 30px auto;
        .caption {
          font-size: 24px;
        }
        li,p {
          font-size: 18px;
        }
        &:before {
          border-right-width: 320px !important;
          border-top: 44px solid #415841 !important
        }
        &:after {
          border-left-width: 320px;
          border-bottom: 44px solid #415841;
          top: -44px;
        }
      }
    }
    &__seven {
      margin-bottom: 10px;
      .form {
        width: 100vw;
      }
    }
    &__eight {
      padding-bottom: 0;
      padding-top: 20px;
      .caption {
        &.-big {
          display: none;
        }
        &.-min {
          display: block;
        }
      }
      .holder {
        flex-wrap: wrap;
        justify-content: center;
      }
      .block {
        &.-two {
          margin: 40px 0;
        }
      }
      .form {
        width: 260px;
        margin: 0 auto;
      }
      .form__caption {
        font-size: 24px;
      }
      .container {
        max-width: 576px;
      }
    }
    &__nine {
      padding-top: 40px;
      margin-bottom: 50px;
      .slick-prev3,
      .slick-next3  {
        display: none !important;
      }
      .slick-dots {
        top: -20px;
      }
      .percent {
        font-size: 26px;
        padding-right: 5px;
      }
      .info {
        width: 320px;
        &:after {
          border-left-width: 320px !important;
        }
        &:before {
          border-right-width: 320px;
        }
      }
      .imagecontainer {
        display: none;
      }
      .name {
        margin: 10px 0;
      }
      .name__inner {
        margin-left: 0%;
        position: relative;
        line-height: 0.2;
      }
    }
    &__ten {
      .paragraph {
        margin-bottom: 1rem;
        br {
          display: none;
        }
      }
      .service {
        padding: 20px;
      }
      .price.-two {
        font-size: 24px;
      }
    }
    &__eleven {
      padding-bottom: 8rem;
      margin-top: 80px;
      .caption.-one {
        font-size: 2rem;
        left: 15px;
        transform: none;
        br {
          display: none;
        }
        .min {
          text-transform: capitalize;
          font-size: 2rem;
        }

      }
      &:after {
        border-top: 60px solid #fff!important;
      }
      .item {
        width: 250px;
      }
      .wrapper {
        margin: 0;
        flex-direction: column;
      }
      .submit {
        width: 100%;
      }
      .block {
        &.-left {
          margin: -80px auto 20px;
        }
        &__show {
          .item {
            margin-bottom: 40px;
          }
        }
      }
    }
    &__twelve {
      .wrap {
        flex-direction: column;
        div {
          margin: 0;
        }
        &__two {
          margin-top: 40px;
          flex-direction: column;
        }
      }
      .form {
        top: -80px;
        padding: 13px;
        text-align: center;
      }
      form {
        margin: 0 auto;
      }
      .contacts {
        width: 100%;
      }
      .map {
        width: 100%;
      }
      .mapcontainer {
        width: 100%;
        margin: 30px 0 0 0;
      }
    }
  }
  footer {
    padding: 40px 0 10px;
    .wrapper {
      flex-direction: column;
    }
    .nav {
      width: 100%;
      flex-direction: column;
      &__link {
        margin: 0 auto;
        padding: 10px;
        &:last-child {
          padding: 10px;
        }
      }
    }
    .socials {
      width: 100%;
      margin: 30px auto;
    }
    .inner {
      &.-left {
        text-align: center;
        margin-top: 30px;
      }
    }
  }
  .slick-next2 {
    display: none !important;
  }
  .bar-wrap {
    display: none;
  }
  .initials_img {
    display: inline-block;
    position: relative;
    width: 125px;
    height: 125px;
    z-index: 0;
    transform: rotate(-45deg);
    overflow: hidden;
    margin: 25px;
    .wrap-img {
      transform: rotate(45deg);
      width: 185px;
      height: 185px;
      position: absolute;
      top: -25%;
      left: -25%;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
@media (max-width: 543px) {
  header {
    .navbar-nav {
      display: block;
      margin-top: 20px;
    }
    .collapse.show {
      display: block;
      position: relative;
    }
    .navbar-brand {
      /*float: right;
      width: 50%;
      margin-right: 50px;
      padding: 0;
      top: -33px;
      position: relative;*/
      display:none;
    }
    .nav-item {
      margin-left: 0 !important;
    }
    .nav-logo {
      display: block;
      padding: 0;
      position: absolute;
      color: #000;
      font-size: 22px;
      font-weight: 400;
      text-align: center;
      line-height: 0.5;
      margin: 0 auto;
      width: 90%;
      top: 10px;
      &:hover {
        text-decoration: none;
      }
      &__middle {
        font-size: 11px;
        font-weight: 300;
        color: #a8947d;
      }
      &__bottom {
        font-size: 8px;
        letter-spacing: 4px;
      }
    }
  }
}
@media (max-width: 480px) {

  .lavnik__eleven .item {
    width: 100%;
  }
  .services__link {
    font-size: 12px;
  }
  .lavnik__one {
    .service p {
      margin: 0;
    }
    .inner_slide {
      padding-top: 10px;
    }
    .wrapper_right {
      width: 100%;
    }
  }
  .btn-outline-success.-tel {
    margin: 15px 0;
  }
  .navbar-brand {
    display: none !important
  }
  .lavnik__two {
    .caption {
      color: #7f674a;;
      background: none;
      -webkit-text-fill-color: #7f674a;
    }
  }
  .tel-mob {
    display: block;
    position: absolute;
    right: 6%;
    top: 0%;
    padding: 14px;
    &:active {
      transform: scale(1.4);
    }
  }
  .popup {
    margin: 0;
    width: 100%;
    .caption {
      font-size: 28px;
    }
    .content {
      font-size: 15px;
      margin-bottom: 5px;
      line-height: 1.4;
      br {
        display:none;
      }
    }
    .form {
      width: 100%;
    }
    .area {
      font-size: 1rem !important;
      background: transparent !important;
    }
  }
  .popup__form .submit {
    width: 100%;
    transform: scale(.9) translateY(-25px);
  }
  .lavnik__five:before {
    display: none;
  }
  .lavnik__five {
    .list__inner {
      &:after {
        border: none !important;
      }
    }
  }
  .slick-next,
  .slick-prev {
    display: none !important;
  }
  .parallax.-one {
    display: none;
  }
  .lavnik__twelve .form__caption br{
    display: none;
  }
  .nav-link {
    padding: 10px 0!important;
  }
  .modal_close {
    right: 20px;
    transform: scale(.6) rotate(45deg);
    top: 7px;
  }
  .lavnik__six:before {
    top: -9vw;
  }
}


.rope {
  .fly {
    width: 0;
    height: 0;
    margin: 0 auto;
    position: relative;
    border-radius: 50%;
    z-index: 1;
  }

  .fly > .fly {
    font-size: 3px;
    position: absolute;
    background-color: #6f583c;
    border-bottom: 1px solid #6f583c;
    top: 0;
    left: -50%;
    width: 3px;
    height: 1px;
    border-radius: 0;
    transform-origin: top center;
    animation: pendulum 8s infinite;
    //animation-timing-function: cubic-bezier(0.8, 0, 0.2, 1);
    animation-timing-function: ease-in-out;
  }

  .fly > .fly > .fly {
    font-size: 0.5px;
    top: 5px;
    left: 0;
    transform-origin: top center;
    animation: pendulum_tel 4s infinite;
    //animation-timing-function: cubic-bezier(0.8, 0, 0.2, 1);
    animation-timing-function: ease-in-out;
  }
  @keyframes tel {
    0% {
      transform: rotate(-8deg);
    }
    50% {
      transform: rotate(2deg);
    }
    100% {
      transform: rotate(-8deg);
    }
  }
  @keyframes pendulum_tel {
    0% {
      top: 4px;
    }
    50% {
      top: 4.5px
    }
    100% {
      top: 4px;
    }
  }
  @keyframes pendulum {
    0% {
      transform: rotate(5deg);
    }
    50% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(5deg);
    }
  }
  .fly.-last {
    position:relative;
    &:after {
      content: url("/_img/rope_tel.png");
      position: absolute;
      top: 3.5px;
      left: -38px;
      transform: rotate(-8deg);
      transform-origin: top center;
      animation: tel 2s infinite;
      animation-timing-function: ease-in-out;
    }
  }
}
.block_modal{
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fff;
  left: 0;
  top: 0;
  padding: 25%;

  .ms-modal-success {
    font-family: "PFDinDisplayPro";
    border: 1px solid rgba(121, 53, 5, 0.52);
    display: inline-block;
    padding: 2px;
    font-size: 16px;
  }
}
.form_success_2{
  .block_modal{

    background: #fff;
    left: 0;
    top: 0;
    padding: 0px;
    position: relative;

  }
}



.req.error {
  border-bottom: 2px solid red;
}
#map {
  width: 100%; height: 100%; padding: 0; margin: 0;
  .ymaps-2-1-65-copyright{
    display: none !important;
  }
}
.inputwrapper {
  font-weight: 300;
  position:relative;
  height:45px;
  margin: 0 0 10px 0 !important;
  span {
    position:absolute;
    font-size: 16px;
    color: #757575;
    opacity:0;
    transition: .3s ease-in-out;
    line-height: 0;
    display: block;
    &.visible{
      top: -5px;
      opacity:1;
    }
  }
}