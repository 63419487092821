/*@font-face {
    font-family: "Geometria";
    font-style: normal;
    font-weight: 400;
    src: url("/_fonts/Geometria/Geometria.ttf") format("woff");
}
@font-face {
    font-family: "Geometria";
    font-style: normal;
    font-weight: 900;
    src: url("/_fonts/Geometria/Geometria-Bold.ttf") format("woff");
}
@font-face {
    font-family: "Geometria";
    font-style: normal;
    font-weight: 300;
    src: url("/_fonts/Geometria/Geometria-Light.ttf") format("woff");
}
@font-face {
    font-family: "Geometria";
    font-style: normal;
    font-weight: 700;
    src: url("/_fonts/Geometria/Geometria-Medium.ttf") format("woff");
}*/
@font-face {
  font-family: "BookAntiqua";
  font-style: normal;
  font-weight: 700;
  src: url("/_fonts/ANTQUAB.ttf") format("woff");
}
@font-face {
  font-family: "BookAntiqua";
  font-style: normal;
  font-weight: 400;
  src: url("/_fonts/BKANT.ttf") format("woff");
}
@font-face {
  font-family: "BookAntiqua";
  font-style: italic;
  font-weight: 400;
  src: url("/_fonts/ANTQUAI.ttf") format("woff");
}
@font-face {
  font-family: "PFDinDisplayPro";
  font-style: normal;
  font-weight: 400;
  src: url("/_fonts/PF DinDisplay Pro.ttf") format("woff");
}
